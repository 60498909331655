import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import {Section, Container, MarkdownContent } from "../components/Section"

const contentPageTemplate = ({ location, data }) => (
  <Layout location={location}>
    <Seo title={data.contentfulContentPages.metaTitle} description={data.contentfulContentPages.metaDescription} />
    <Section ept="160px" epb="60px" xpt="140px" xpb="40px" pt="90px" pb="30px"  bgColor="#F8FAFB" className="section-content">       
        <Container maxWidth="1280px">
            <MarkdownContent>
                <h1>{data.contentfulContentPages.pageTitle}</h1>
                <div
                    dangerouslySetInnerHTML={{
                    __html: data.contentfulContentPages.content.childMarkdownRemark.html,
                    }}
                />
            </MarkdownContent>
        </Container>
    </Section>
  </Layout>
)

export default contentPageTemplate

export const pageQuery = graphql`
  query contentPageTemplateQuery($id: String!) {
    contentfulContentPages(id: { eq: $id }) {
        metaTitle
        metaDescription
        pageTitle
        content {
            childMarkdownRemark {
                html
            }
        }
    }
  }
`